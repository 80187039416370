import React from 'react';

import Accordion from 'react-bootstrap/Accordion';
import Catalogue_LG_audio from './catalogue_doc/lg_audio_catalogue.pdf';
import Catalogue_LG_tv from './catalogue_doc/LGTV.pdf';
import Catalogue_LG_split from './catalogue_doc/lg_congelateur_catalogue.pdf';
import Catalogue_LG_machine from './catalogue_doc/lg_split_catalogue.pdf';
import Catalogue_LG_micro from './catalogue_doc/lg_micro_catalogue.pdf';
import Catalogue_LG_cuisiniere from './catalogue_doc/lg_machine_catalogue.pdf';
import Catalogue_LG_lave from './catalogue_doc/lg_ref_catalogue.pdf';
import Catalogue_LG_congelateur from './catalogue_doc/lg_cuisiniere_catalogue.pdf';
import Catalogue_LG_ref from './catalogue_doc/lg_lave_vaisselle_catalogue.pdf';



function Catalogue_LG() {
  return <>
    
    <Accordion defaultActiveKey="0" flush>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Catalogue LG AUDIO</Accordion.Header>
        <Accordion.Body>
            <div>
        <iframe src={Catalogue_LG_audio} style={{'width':'100%', 'height':'500px'}} />
        </div>   
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Catalogue LG TV</Accordion.Header>
        <Accordion.Body>
        <div>
        <iframe src={Catalogue_LG_tv} style={{'width':'100%', 'height':'500px'}} />
        </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Catalogue LG CLIMATISEUR</Accordion.Header>
        <Accordion.Body>
        <div>
        <iframe src={Catalogue_LG_split} style={{'width':'100%', 'height':'500px'}} />
        </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>Catalogue LG MICRO-ONDE</Accordion.Header>
        <Accordion.Body>
        <div>
        <iframe src={Catalogue_LG_micro} style={{'width':'100%', 'height':'500px'}} />
        </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>Catalogue LG MACHINE A LAVER</Accordion.Header>
        <Accordion.Body>
        <div>
        <iframe src={Catalogue_LG_machine} style={{'width':'100%', 'height':'500px'}} />
        </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header>Catalogue LG REFRIGERATEUR</Accordion.Header>
        <Accordion.Body>
        <div>
        <iframe src={Catalogue_LG_ref} style={{'width':'100%', 'height':'500px'}} />
        </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
        <Accordion.Header>Catalogue LG CONGELATEUR</Accordion.Header>
        <Accordion.Body>
        <div>
        <iframe src={Catalogue_LG_congelateur} style={{'width':'100%', 'height':'500px'}} />
        </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="7">
        <Accordion.Header>Catalogue LG CUISINIERE</Accordion.Header>
        <Accordion.Body>
        <div>
        <iframe src={Catalogue_LG_cuisiniere} style={{'width':'100%', 'height':'500px'}} />
        </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="8">
        <Accordion.Header>Catalogue LG LAVE VAISSELLE</Accordion.Header>
        <Accordion.Body>
        <div>
        <iframe src={Catalogue_LG_lave} style={{'width':'100%', 'height':'500px'}} />
        </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  </>
    
  
}

export default Catalogue_LG