

const serviceData = [
    {
      icon: "ri-store-3-line",
      title: "Showroom",
      subtitle: "La Société Fouani Congo dispose dans tous les magasins les Produits.",
      bg: "#fdefe6",
    },
    {
      icon: "ri-radio-2-line",
      title: "Audio",
      subtitle: "Profitez de vos chansons préférées sur vos appareils mobiles",
      bg: "#ceebe9",
    },
    {
      icon: "ri-tv-2-line",
      title: "Tv",
      subtitle: "Surfer sur le web,accéder à des sites de vidéos en streaming.",
      bg: "#e2f2b2",
    },
    {
      icon: "ri-fridge-line",
      title: "Refrigerator",
      subtitle: "Beau à l'extérieur et puissant à l'intérieur, efficacité énergétique.",
      bg: "#d6e5fb",
    },
  ];
  
  export default serviceData;
  