import React from 'react'
import productHs5 from "../../assets/images/8014T.png";
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import '../../styles/slider.css';
function Product_Hs5() {
  return (
    <div className='card display-flex' style={{padding: '8px 20px',marginLeft:'20px', alignItems:'center'}}>
    <Link to='/shop/133'><motion.img whileHover={{ scale: 0.9 }}  className='product--image' src={productHs5} alt="" /></Link>
    
  </div>
  )
}

export default Product_Hs5