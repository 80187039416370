


import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import {motion} from 'framer-motion';

import { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';


import './services.css';

import serviceData from "../assets/data/serviceData";

const Services = () => {

    useEffect(()=> {
        Aos.init({duration:2000});
      },[])

  return <section className="services services">
    <Container data-aos="fade-up">
        <Row>

            {
                serviceData.map((item, index) =>(
                    <Col lg='3' md='4'>
                <motion.div whileHover={{ scale: 1.1 }} className="services__item" style={{background: `${item.bg}`}} key={index} >
                   <span><i className={item.icon}></i></span>
                   <div>
                    <h3>{item.title}</h3>
                    <p>{item.subtitle}</p>
                    </div> 
                </motion.div>
            </Col>
                ))
            }

            
        </Row>
    </Container>
  </section>
}

export default Services