import React from 'react'
import productPaco from "../../assets/images/Paco.png";//audio
import { motion } from 'framer-motion';

import '../../styles/slider.css';


function Product_marquePaco() {
  return (
    <div className='card display-flex' style={{padding: '8px 20px',marginLeft:'20px'}}>
    <motion.img whileHover={{ scale: 0.9 }}  className='product--image' src={productPaco} alt="" />

  </div>
  )
}

export default Product_marquePaco