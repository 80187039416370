import React from 'react'
import Corredo2 from "../../assets/images/1- 60x60 Ceramic Grey_3.jpg";//audio
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import '../../styles/slider.css';


function Product_Cor2() {
  return (
    <div className='card display-flex' style={{padding: '8px 20px',marginLeft:'20px', alignItems:'center'}}>
    <Link to='/shop/222'><motion.img whileHover={{ scale: 0.9 }}  className='product--image' src={Corredo2} alt="" /></Link>
    <h6 className='title'>Corredo detail</h6>
    
    <Link to='/shop/222'><motion.button className='button-slider' whileTap={{scale: 1.2}}  >Plus detail</motion.button></Link>
  </div>
  )
}

export default Product_Cor2