


import React from 'react';
import { Container, Row, Col, Form, FormGroup } from 'reactstrap';
import Helmet from '../components/Helmet/Helmet';
import CommonSection from '../components/Ul/CommonSection';

import '../styles/checkout.css';
import { useSelector } from "react-redux";


const Checkout = () => {

  const totalQty = useSelector(state=>state.cart.totalQuantity)
  const totalAmount = useSelector(state=>state.cart.totalAmount)


  return (
    <Helmet title="checkout">
      <CommonSection title="checkout" />
      <section>
        <Container className="text-center">
           
            <Col lg='4'>
              <div className="checkout__cart">
                <h6>Total Qty: <span>{totalQty} items</span></h6>
                <h6>Subtotal: <span>${totalAmount}</span></h6>
                
                <h4>Total Cost: <span>${totalAmount}</span></h4>
                <a href="https://api.whatsapp.com/send?phone=243858955999" class="whatsapp"><button className="buy_btn auth__btn magni w-100">
                Contactez-nous <i class="ri-whatsapp-line"></i>
              </button></a>
              </div>
               
            </Col>
         
        </Container>
      </section>
    </Helmet>
  );
  
};

export default Checkout;