
import React, {useState, useEffect} from 'react';

import '../styles/shop.css';
import Image from 'react-bootstrap/Image';

import products from '../assets/data/products';
//courel
import Carousel from 'react-bootstrap/Carousel';
import ExampleCarouselImage from "../assets/images/WEBSITE COVER (1).jpg";
import ExampleCarouselImage2 from "../assets/images/WEBSITE COVER (1).jpg";
import ExampleCarouselImage3 from "../assets/images/WEBSITE COVER (1).jpg";

import 'react-multi-carousel/lib/styles.css';

import Helmet from '../components/Helmet/Helmet';
import '../styles/home.css';

import { Container, Row, Col } from 'reactstrap';


import ProductsList from '../components/Ul/ProductsList';


import Product_marqueCorredo1 from '../components/Product-Slider/Product_marqueCorredo';
import Product_marqueDorco1 from '../components/Product-Slider/Product_marqueDorco';
import Product_marqueHisense1 from '../components/Product-Slider/Product_marqueHisense';
import Product_marqueLg1 from '../components/Product-Slider/Product_marqueLg';
import Product_marqueNutribom1 from '../components/Product-Slider/Product_marqueNutribom';
import Product_marquePaco1 from '../components/Product-Slider/Product_marquePaco';
import Product_marqueEnzahome1 from '../components/Product-Slider/Product_marqueEnzahome';
import Carousel1 from 'react-multi-carousel';
import Showroom from '../components/Showroom/Showroom';

import Catalogue_LG from '../components/catalogue_LG/Catalogue_LG';



const Lg = () => {

    const [productsData, setProductsData] = useState(products)

    const handleFilter = (e)=>{
      const filterValue = e.target.value;
      if (filterValue === "LG audio") {
        const filteredProducts = products.filter(
          (item) => item.category === "LG audio"
        );
  
        setProductsData(filteredProducts);
      }
  
      if (filterValue === "LG tv") {
        const filteredProducts = products.filter(
          (item) => item.category === "LG tv"
        );
  
        setProductsData(filteredProducts);
      }
  
  
      if (filterValue === "LG refrigerateur") {
        const filteredProducts = products.filter(
          (item) => item.category === "LG refrigerateur"
        );
  
        setProductsData(filteredProducts);
      }
  
  
      if (filterValue === "LG climatiseur") {
        const filteredProducts = products.filter(
          (item) => item.category === "LG climatiseur"
        );
  
        setProductsData(filteredProducts);
      }
  
      if (filterValue === "LG machine à laver") {
        const filteredProducts = products.filter(
          (item) => item.category === "LG machine à laver"
        );
  
        setProductsData(filteredProducts);
      }
  
      if (filterValue === "LG micro-onde") {
        const filteredProducts = products.filter(
          (item) => item.category === "LG micro-onde"
        );
  
        setProductsData(filteredProducts);
      }
  
      if (filterValue === "LG lave vaisselle") {
        const filteredProducts = products.filter(
          (item) => item.category === "LG lave vaisselle"
        );
  
        setProductsData(filteredProducts);
      }
  
      if (filterValue === "LG cuisiniere") {
        const filteredProducts = products.filter(
          (item) => item.category === "LG cuisiniere"
        );
  
        setProductsData(filteredProducts);
      }
  
      if (filterValue === "LG congelateur") {
        const filteredProducts = products.filter(
          (item) => item.category === "LG congelateur"
        );
  
        setProductsData(filteredProducts);
      }
    };

    const [lgAudioProducts, setLgAudioProducts] = useState([]);
    const [lgSplitProducts, setLgSplitProducts] = useState([]);
    const [lgRefrigerateurProducts, setLgRefrigerateurProducts] = useState([]);
    const [lgTvProducts, setLgTvProducts] = useState([]);
    const [lgMachineProducts, setLgMachineProducts] = useState([]);
    const [lgMicroProducts, setLgMicroProducts] = useState([]);
    const [lgAirProducts, setLgAirProducts] = useState([]);
    
    const year = new Date().getFullYear();

    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 1024 },
        items: 5,
      },
      desktop: {
        breakpoint: { max: 1024, min: 800 },
        items: 4,
      },
      tablet: {
        breakpoint: { max: 800, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
      },
    };
  
    useEffect(()=>{
        const filteredLgAudioProducts = products.filter(item=> item.category
          ==='LG audio'
          );
        const filteredLgSplitProducts = products.filter(item=> item.category
          ==='LG climatiseur'
          );
  
        const filteredLgRefrigerateurProducts = products.filter(item=> item.category
          ==='LG refrigerateur'
          );
  
          const filteredLgTvProducts = products.filter(item=> item.category
            ==='LG tv'
            );
  
        const filteredLgMachineProducts = products.filter(item=> item.category
          ==='LG machine à laver'
          );
        const filteredLgMicroProducts = products.filter(item=> item.category
              ==='LG micro-onde'
          );
  
          const filteredLgAirProducts = products.filter(item=> item.category
              ==='LG lave vaisselle'
          );
  
          setLgAudioProducts(filteredLgAudioProducts);
          setLgSplitProducts(filteredLgSplitProducts);
          setLgRefrigerateurProducts(filteredLgRefrigerateurProducts);
          setLgTvProducts(filteredLgTvProducts);
          setLgMachineProducts(filteredLgMachineProducts);
          setLgMicroProducts(filteredLgMicroProducts);
          setLgAirProducts(filteredLgAirProducts);
    }, []);

  return <Helmet title={"Lg"}>
    <section className='pt-1'>
    <Carousel>
      <Carousel.Item>
        <img src={ExampleCarouselImage} text="" />
        <Carousel.Caption>
        
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img src={ExampleCarouselImage} text=""  />
        <Carousel.Caption>
          
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img src={ExampleCarouselImage2} text="" />
        <Carousel.Caption>
         
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
    </section>
  
    <section>
        <Container>
          <Row>
            <Col lg='3' md='6'>
              <div className="filter__widget">
                <select onChange={handleFilter}>
                <option>Filter By LG</option>
                  <option value="LG audio">Audio</option>
                  <option value="LG tv">tv</option>
                  <option value="LG refrigerateur">Refrigerateur</option>
                  <option value="LG climatiseur">climatiseur</option>
                  <option value="LG machine à laver">Machine à laver</option>
                  <option value="LG micro-onde">Micro-Onde</option>
                  <option value="LG lave vaisselle">Lave Vaisselle</option>
                  <option value="LG cuisiniere">Cuisiniere</option>
                  <option value="LG congelateur">Congelateur</option>
                </select>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className='pt-0'>
        <Container>
          <Row>
            {
              productsData.length === 0 ? (
                <h1 className='text-center fs-4'>No products are found!</h1>
              ): (
                <ProductsList data={productsData} />
              )}
          </Row>
        </Container>
      </section>
      <section className='pb-1'>
      <Image src={ExampleCarouselImage2} fluid />;
      </section>
    <section className="trending__products">
      <Container>
        <Row>
          <Col lg='12' className='text-center mb-5'>
            <h2 className='section__title'>LG Audio</h2>
          </Col>
        <ProductsList data={lgAudioProducts} />          
        </Row>
      </Container>
    </section>
    <section className="best__sales">
      <Container>
      <Row>
          <Col lg='12' className='text-center mb-5'>
            <h2 className='section__title'>LG Climatiseur</h2>
          </Col>
         <ProductsList data={lgSplitProducts} />     
        </Row>
      </Container>
    </section>
    <section className="best__sales">
      <Container>
      <Row>
          <Col lg='12' className='text-center mb-5'>
            <h2 className='section__title'>LG Refrigerateur</h2>
          </Col>
         <ProductsList data={lgRefrigerateurProducts} /> 
        </Row>
      </Container>
    </section>
    <section className="best__sales">
      <Container>
      <Row>
          <Col lg='12' className='text-center mb-5'>
            <h2 className='section__title'>LG Tv</h2>
          </Col>
         <ProductsList data={lgTvProducts} /> 
        </Row>
      </Container>
    </section>
    <section className="best__sales">
      <Container>
      <Row>
          <Col lg='12' className='text-center mb-5'>
            <h2 className='section__title'>LG Machine à Laver</h2>
          </Col>
         <ProductsList data={lgMachineProducts} /> 
        </Row>
      </Container>
    </section>
    <section className="best__sales">
      <Container>
      <Row>
          <Col lg='12' className='text-center mb-5'>
            <h2 className='section__title'>LG Micro-Onde</h2>
          </Col>
         <ProductsList data={lgMicroProducts} /> 
        </Row>
      </Container>
    </section>
    <section className="best__sales">
      <Container>
      <Row>
          <Col lg='12' className='text-center mb-5'>
            <h2 className='section__title'>LG Lave Vaisselle</h2>
          </Col>
         <ProductsList data={lgAirProducts} /> 
        </Row>
      </Container>
    </section>
    <section className="trending__products hisense">
      <Container>
        <Row>
          <Col lg='12' className='text-center mb-5'>
            <h2 className='section__title titre'> Nos Marques</h2>
          </Col>

          <Carousel1 responsive={responsive} className='text-center'>
             <Product_marqueHisense1 />
             <Product_marqueCorredo1 />
              <Product_marqueEnzahome1 />
              <Product_marqueDorco1 />
              <Product_marqueLg1 />
              <Product_marqueNutribom1 />
              <Product_marquePaco1 />
              <Product_marqueHisense1 />          
           </Carousel1>
        </Row>
      </Container>
    </section> 
    <section className="trending__products hisense" data-aos="fade-up">
      <Container>
      <Col lg='12' className='text-center mb-5 md'>
      <h2 className='section__title titre'> Showroom</h2>
          </Col>
        <Showroom />
        <Catalogue_LG />
      </Container>
    </section> 
  </Helmet>;
};


export default Lg