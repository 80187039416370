import React from 'react'
import PerkinImg3 from "../../assets/images/perkins2.png";//audio
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import '../../styles/slider.css';
function Product_Perk3() {
  return (
    <div className='card display-flex' style={{padding: '8px 20px',marginLeft:'20px', alignItems:'center'}}>
    <Link to='/shop/252'><motion.img whileHover={{ scale: 0.9 }}  className='product--image' src={PerkinImg3} alt="" /></Link>
    <Link to='/shop/252'><h6 className='title'>Perkins</h6></Link>
    
    <Link to='/shop/252'><motion.button className='button-slider' whileTap={{scale: 1.2}}  >Plus detail</motion.button></Link>
  </div>
  )
}

export default Product_Perk3