

import React from 'react';
import { IoIosArrowForward } from "react-icons/io";
import { PiMapPinAreaFill } from "react-icons/pi";
import { BsFillTelephoneFill } from "react-icons/bs";
import { FaFacebook } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { BiLogoGmail } from "react-icons/bi";
import './footer.css';
import codeQR from '../../assets/images/FouaniCongo.png'

import { FaTiktok } from "react-icons/fa";

import { Container, Row, Col, ListGroup, ListGroupItem } from 'reactstrap';
import { Link } from 'react-router-dom';

const Footer = () => {

const year = new Date().getFullYear()

  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col lg='4' className='mb-4' md='6'>
          <div className='logo'>
              
              <div>
                <h4 className='quick__links-title lien'>Fouani Congo</h4>
              </div>
            </div>
            <p className='footer__text mt-2 mb-3 lien'>
            Direction Générale
Av. Flambeau Q. N'Dolo
( en face de Bralima, diagonale au station Total)

            </p>
            <a href="https://www.google.com/maps/place/Fouani+Congo/@-4.3104047,15.3280367,20.25z/data=!4m6!3m5!1s0x1a6a341072327717:0xd5ef991561e95bd!8m2!3d-4.3102909!4d15.3285146!16s%2Fg%2F11gh03zlmn?hl=fr&entry=ttu"><img src={codeQR} alt="" style={{width:'50%', height:'50%', cursor:'pointer'}}/></a>
          </Col>

          <Col lg='3' md='3' className='mb-4'>
            <div className="footer__quick-links">
              <h4 className='quick__links-title lien'>Top Categories</h4>
              <ListGroup>
              <ListGroupItem className='ps-0 border-0 lien'>
                  <Link to='/catalogue_fouanicongo' className='lien'><i class="" ><IoIosArrowForward /></i>Catalogue fouanicongo</Link>
                </ListGroupItem >
              
                <ListGroupItem className='ps-0 border-0 lien'>
                  <Link to='/' className='lien'><i class="" ><IoIosArrowForward /></i>LG</Link>
                </ListGroupItem >

                <ListGroupItem className='ps-0 border-0 lien'>
                  <Link to='/' className='lien'><i class="" ><IoIosArrowForward /></i>Hisense</Link>
                </ListGroupItem>

                <ListGroupItem className='ps-0 border-0 lien'>
                  <Link to='/shop/228' className='lien'><i class="" ><IoIosArrowForward /></i>Paco</Link>
                </ListGroupItem>

                <ListGroupItem className='ps-0 border-0 lien'>
                  <Link to='/shop/232' className='lien'><i class="" ><IoIosArrowForward /></i>Colors</Link>
                </ListGroupItem>
                <ListGroupItem className='ps-0 border-0 lien'>
                  <Link to='/shop/214' className='lien'><i class="" ><IoIosArrowForward /></i>Corredo</Link>
                </ListGroupItem>
                <ListGroupItem className='ps-0 border-0 lien'>
                  <Link to='/shop/250' className='lien'><i class="" ><IoIosArrowForward /></i>Perkins</Link>
                </ListGroupItem>
                <ListGroupItem className='ps-0 border-0 lien'>
                  <Link to='/shop/256' className='lien'><i class="" ><IoIosArrowForward /></i>Saco</Link>
                </ListGroupItem>
              </ListGroup>
            </div>
          </Col>

          <Col lg='2' md='3' className='mb-4'>
          <div className="footer__quick-links">
              <h4 className='quick__links-title lien'>Useful Links</h4>
              <ListGroup>
                <ListGroupItem className='ps-0 border-0 lien'>
                  <Link to='/shop' className='lien'><i class="" ><IoIosArrowForward /></i>Shop</Link>
                </ListGroupItem >

                <ListGroupItem className='ps-0 border-0'>
                  <Link to='/cart' className='lien'><i class="" ><IoIosArrowForward /></i>Panier</Link>
                </ListGroupItem>

                <ListGroupItem className='ps-0 border-0'>
                  <Link to='/login' className='lien'><i class="" ><IoIosArrowForward /></i>Login</Link>
                </ListGroupItem>

                <ListGroupItem className='ps-0 border-0'>
                  <Link to='#' className='lien'><i class="" ><IoIosArrowForward /></i>Privacy Policy</Link>
                </ListGroupItem>
              </ListGroup>
            </div>
          </Col>

          

          <Col lg='3' md='4'>
          <div className="footer__quick-links">
              <h4 className='quick__links-title lien'>Contact</h4>
              <ListGroup className='footer__contact'>
                <ListGroupItem className='ps-0 border-0 d-flex align-items-center 
                gap-2'>
                  <span><a href="https://www.google.com/maps/place/Fouani+Congo/@-4.3104047,15.3280367,20.25z/data=!4m6!3m5!1s0x1a6a341072327717:0xd5ef991561e95bd!8m2!3d-4.3102909!4d15.3285146!16s%2Fg%2F11gh03zlmn?hl=fr&entry=ttu"><PiMapPinAreaFill className='lien'/></a></span>
                  <p className='lien'>123 AV.kapanga, C/Barumbu</p>
                </ListGroupItem >

                <ListGroupItem className='ps-0 border-0 d-flex align-items-center 
                gap-2'>
                <span><a href="https://api.whatsapp.com/send?phone=243858955999"><BsFillTelephoneFill className='lien'/></a>  <a href="https://api.whatsapp.com/send?phone=243858955999"><FaWhatsapp className='lien' /></a></span>
                  <p className='lien'>+243 999 555 999</p>
                </ListGroupItem>

                <ListGroupItem className='ps-0 border-0 d-flex align-items-center 
                gap-2'>
                <span><a href="https://www.facebook.com/fouanicongo"><FaFacebook className='lien'/></a> <a href="https://www.tiktok.com/@fouanicongo?_t=8ZUUKudmx&_r=1"><FaTiktok className='lien' /></a> <a href="https://www.instagram.com/fouanicongo"><FaInstagram className='lien'/></a></span>
                  <p className='lien'>fouanicongo</p>
                </ListGroupItem>

                <ListGroupItem className='ps-0 border-0 d-flex align-items-center 
                gap-2'>
                <span><a href="https://mail.google.com/mail/u/0/#sent/FMfcgzGwHpbdbscptxTknzLxdlvgqZTn"><BiLogoGmail className='lien'/></a></span>
                  <p className='lien'>bilal.kinshasa@fouani.com</p>
                </ListGroupItem>
              </ListGroup>
            </div>
          </Col>
          <Col lg='12'>
            <p className='footer__copyright lien'>Copyright {year} developped by Fouani Congo. All rights reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
  
};

export default Footer;