
import { Routes, Route, Navigate } from 'react-router-dom';

import Home from '../pages/Home';
import Shop from '../pages/Shop';
import Cart from '../pages/Cart';
import LG from '../pages/Lg';
import Hisense from '../pages/Hisense'
import ProductDetails from '../pages/ProductDetails';
import Checkout from '../pages/Checkout';
import Login from '../pages/Login';
import Signup from '../pages/Signup';
import Catalogue_LG from '../pages/Catalogue_LG';
import Catalogue_Hisense from '../pages/Catalogue_Hisense';
import Catalogue_paco_corredo_perkins_saco from '../pages/Catalogue_paco_corredo_perkins_saco';
import NotFound from '../pages/NotFound'
//import ProtectedRoute from './ProtectedRoute';


const Routers = () => {
  return (
    <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path='/' element={<Navigate to='home'/>}/> 
        <Route path='home' element={<Home/>} />
        <Route path='shop' element={<Shop/>} />
        <Route path='lg' element={<LG/>} />
        <Route path='hisense' element={<Hisense/>} />
        <Route path='shop/:id' element={<ProductDetails/>} />
        <Route path='cart' element={<Cart/>} />
        <Route path='catalogue_fouanicongo' element={<Catalogue_LG/>} />
        <Route path='checkout' element={<Checkout/>} />

        
        <Route path='login' element={<Login/>} />
        <Route path='signup' element={<Signup/>} />
    </Routes>
  )
};
export default Routers;