

import React from 'react'
//import { Container } from 'reactstrap'
import '../../styles/common-section.css'
//import Carousel from 'react-bootstrap/Carousel';


const CommonSection = ({ title }) => {
  return  <>
  <section className='pt-1'>
    <img src={title} text="" />
    </section>
   {/** <section className="common__section">
        <Container>
            <img src={title} alt="" />
        </Container>
  </section> */}
  </>
}

export default CommonSection