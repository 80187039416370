import React, { useState } from 'react';  
import '../GalleryLg/Gallery.css';   

import img11 from '../../../assets/images/banner/Hisense 1 Tv.png';
import img21 from "../../../assets/images/banner/Hisense 2.png";
import img31 from '../../../assets/images/banner/Hisense 3.png';
import img41 from '../../../assets/images/full-shot-couple-dog-home.a65754f1aee13f0c2e83.jpg';
import img51 from '../../../assets/images/banner/Hisense 4.png';

const images = [  
  img11,
  img21,
  img31,
  img51,

   
];  

function GalleryHisense() {  
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);  

  // Ouvrir l'image sélectionnée  
  const openImage = (index) => {  
    setSelectedImageIndex(index);  
  };  

  // Fermer le carrousel  
  const closeCarousel = () => {  
    setSelectedImageIndex(null);  
  };  

  // Passer à l'image suivante  
  const nextImage = () => {  
    setSelectedImageIndex((prevIndex) =>   
      (prevIndex !== null && prevIndex < images.length - 1 ? prevIndex + 1 : 0)  
    );  
  };  

  // Revenir à l'image précédente  
  const prevImage = () => {  
    setSelectedImageIndex((prevIndex) =>   
      (prevIndex !== null && prevIndex > 0 ? prevIndex - 1 : images.length - 1)  
    );  
  };  

  // Gestion des événements du clavier  
  const handleKeyDown = (event) => {  
    if (selectedImageIndex !== null) {  
      if (event.key === 'ArrowRight') {  
        nextImage();  
      } else if (event.key === 'ArrowLeft') {  
        prevImage();  
      } else if (event.key === 'Escape') {  
        closeCarousel();  
      }  
    }  
  };  

  // Ajoutez un écouteur d'événements pour le clavier lorsque l'image est sélectionnée  
  React.useEffect(() => {  
    window.addEventListener('keydown', handleKeyDown);  
    return () => {  
      window.removeEventListener('keydown', handleKeyDown);  
    };  
  }, [selectedImageIndex]);  

  return (  
    <div>  
      <div className="gallery-grid">  
        {images.map((image, index) => (  
          <div key={index} className="grid-item" onClick={() => openImage(index)}>  
            <img src={image} alt={`Image ${index + 1}`} className="thumbnail" />  
          </div>  
        ))}  
      </div>  

      {selectedImageIndex !== null && (  
        <div className="full-image-modal" onClick={closeCarousel}>  
          <img src={images[selectedImageIndex]} alt="Fullscreen" className="full-image" />  
          <button className="nav-button prev" onClick={(e) => { e.stopPropagation(); prevImage(); }}>  
            Précédent  
          </button>  
          <button className="nav-button next" onClick={(e) => { e.stopPropagation(); nextImage(); }}>  
            Suivant  
          </button>  
          <button className="close-button" onClick={closeCarousel}>Fermer</button>  
        </div>  
      )}  
    </div>  
  );  
}  


export default GalleryHisense;