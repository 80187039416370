import React from 'react'
import productHs4 from "../../assets/images/vaisselle_hisense.jpg";
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import '../../styles/slider.css';

function Product_Hs4() {
  return (
    <div className='card display-flex' style={{padding: '8px 20px',marginLeft:'20px', alignItems:'center'}}>
    <Link to='/shop/131'><motion.img whileHover={{ scale: 0.9 }}  className='product--image' src={productHs4} alt="" /></Link>
    
  </div>
  )
}

export default Product_Hs4