import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './showroom.css'

function Showroom() {
  return (
    <Container>
    <Row>
      <Col>
            <h6 className="titre__showroom">Barumbu</h6>
            <p className='phrase'>AV.Flambeau Q. N'Dolo ( en face de Bralima, diagonale au station Total)
                Tél: 0858955999
            </p><br />
            <h6 className="titre__showroom">Commerce</h6>
            <p className='phrase'>Croisement des avenues Kasavubu vers Mama Yemo, Direction Zando Tél: 0899829200
            </p>
      </Col>
      <Col>
            <h6 className="titre__showroom">MATETE, DeBonhomme</h6>
            <p className='phrase'>Magasin Debnhomme Blvd Lumumba numéro 73(Kin Marché Debonhomme)
                Tél: 0900007451
            </p><br />
            <h6 className="titre__showroom">Lemba</h6>
            <p className='phrase'>Station sous region à coté du Kin marché sur Ex Baypas
                Tél: 0990588887
            </p>
      </Col>
      <Col>
            <h6 className="titre__showroom">Gombe</h6>
            <p className='phrase'>Magasin Gambela.Entré de la Grande porte Marché gambela (Grand -porte) Tel: 0853 923 442
            </p><br />
            <h6 className="titre__showroom">Kasavubu</h6>
            <p className='phrase'>, Maison Amazon Av. de l’équateur numéro 87 EX Bingo
                Tel: 0903500800
            </p>

      </Col>
      <Col>
            <h6 className="titre">Gombe</h6>
            <p className='phrase'>Boulevard du 30 juin, immeuble 1113 Réf, en face de Afriland First Bank
            Tél: 0900900811
            </p><br />
            <h6 className="titre">
            < i class="ri-settings-2-line"></i> Services Techniques</h6>
            <p className='phrase'>service.kinshasa@fouani.com
                Tél:(+243) 900 077 774 - 999 977 774
            </p>
      </Col>
    </Row>
  </Container>
  )
}

export default Showroom