import React from 'react'
import Paco2 from "../../assets/images/paco_stab1.png";//audio
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import '../../styles/slider.css';


function Product_Pac2() {
  return (
    <div className='card display-flex' style={{padding: '8px 20px',marginLeft:'20px', alignItems:'center'}}>
    <Link to='/shop/228'><motion.img whileHover={{ scale: 0.9 }}  className='product--image' src={Paco2} alt="" /></Link>
    <Link to='/shop/228'><h6 className='title'>Paco detail</h6></Link>
    
    <motion.button className='button-slider' whileTap={{scale: 1.2}}  ><Link to='/shop/228'>Plus detail</Link></motion.button>
  </div>
  )
}

export default Product_Pac2