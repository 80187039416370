import React from 'react';
import './featured3.css';

import { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';

// nouvelle section images

import img12 from '../../assets/images/hisense-tv-a4.jpg';
import img22 from '../../assets/images/hisense-laser-tv-pure-color.jpg';
import img32 from '../../assets/images/hisense-laser-tv-hdr.jpg';
import img42 from '../../assets/images/hisense-laser-tv-4k-picture-quality.jpg';
import img52 from '../../assets/images/hisense-soundbar-powerful-bass.jpg';
 //hisense

function Featured3() {

    useEffect(()=> {
        Aos.init({duration:2000});
      },[])  
  return (
    <div className='featured'>
        <h1 className='featured-text mt-2'></h1>
        <p className='featured-tex'data-aos="fade-right">la société Fouani Congo est toujours là pour des solutions durables et des qualités supérieures</p>

        {/* Nouvelle section */}

        <div className='container'>
            <img src={img12} alt="" data-aos="fade-up" />
            <img src={img22} alt="" data-aos="fade-up" />

            <img className='span-3 image-grid-row-2' src={img32} alt="" data-aos="fade-left" />
            
            <img src={img42} alt="" data-aos="fade-up" />
            <img src={img52} alt="" data-aos="fade-up" />
            <div className='span-2 right-img-details'>
                <p data-aos="fade-right">Hisense est le 4ème fabricant mondial de TV et 3ème de réfrigérateurs. Nous proposons des produits designs équipés des dernières technologies à prix abordables. Téléviseurs 4K UHD, QLED, OLED, TV connectée ou Laser TV.</p>
                <button className='btn'></button>
            </div>
            <div className='span-3 img-details'>
                <div className='top' data-aos="fade-up">
                    <h2>les meilleurs prix de Kinshasa</h2>
                    
                </div>
                <div className='info-grid'>
                    <div>
                        <div className='info'>
                            <p className='bold'>Climatiseur:</p><p style={{color:'crimson'}}>Hisense</p>
                        </div>
                        <div className='info'>
                            <p className='bold'>Refrigerateur:</p><p style={{color:'crimson'}}>Hisense</p>
                        </div>
                    </div>
                    <div>
                        <div className='info'>
                            <p className='bold'>Machine à laver:</p><p style={{color:'crimson'}}>Hisense</p>
                        </div>
                        <div className='info'>
                        <p className='bold'>Télévision:</p><p style={{color:'crimson'}}>Hisense</p>
                        </div>
                    </div>
                </div>
            </div>
          
        </div>


    </div>
  )
}

export default Featured3