import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Corredo from '../../assets/images/1- 60x60 Ceramic Grey_11.jpg'
import PacoImage from '../../assets/images/paco_stabA.jpg'
import Corredo2 from '../../assets/images/1-60x60 Ceramic Black_1.jpg'
//import Button from 'react-bootstrap/Button';


function CorredoPaco() {
  return (
    <Container>
      <h2 className='text-center mb-3'>Corredo & Paco</h2>
    <Row>
        <Col xs={6} md={3}>
          <h2 className='text-center'>Corredo Gray</h2>
          
          <Image src={Corredo} fluid />
        </Col>
        <Col xs={6} md={3}>
          <h2 className='text-center'>Paco 15OOVA</h2>
          <Image src={PacoImage} fluid />
        </Col>
        <Col xs={6} md={3}>
        <h2 className='text-center'>Corredo Black</h2>
          <Image src={Corredo2} fluid />
        </Col>
        <Col xs={6} md={3}>
          <h2 className='text-center'>Paco 2OOOVA</h2>
          <Image src={PacoImage} fluid />
        </Col>
    </Row>
  </Container>
  )
}

export default CorredoPaco