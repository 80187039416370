import React from 'react';
import '../Best3/best3.css';
import img1 from '../../assets/images/poster1.jpg';
import img2 from '../../assets/images/EMTP23101.jpg';
import img3 from '../../assets/images/poster3.jpg';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import './emtop.css'

function Emtop() {
  useEffect(()=> {
    Aos.init({duration:2000});
  },[])
  return (
    <div className='best'>
        <h2 style={{color:'crimson'}}></h2>
        {/**<div>
            <p><span className='bold'>All</span></p>
            <p>Commercial</p>
            <p>Commercial</p>
            <p>Commercial</p>
        </div> */}
        <div className='container emtop'>
            <img src={img1} alt="" data-aos="fade-right" />
            <img src={img2} alt="" data-aos="fade-up"/>
            <img src={img3} alt="" data-aos="fade-left"/>
        </div>
    </div>
  )
}

export default Emtop