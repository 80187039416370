import React from 'react';
import './featured.css';

import { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';

import img11 from '../../assets/images/africa-computerelectronics-supercategory-9.jpg';
import img21 from "../../assets/images/winter-sports-concept-composition.59e504a787b9d4d098dc.jpg";
import img31 from '../../assets/images/view-woman-gas-station.b211a1d2da70eacbfa8e.jpg';
import img41 from '../../assets/images/full-shot-couple-dog-home.a65754f1aee13f0c2e83.jpg';
import img51 from '../../assets/images/corredo_banner_3.jpg';

//LG featured

function Featured() {
    useEffect(()=> {
        Aos.init({duration:2000});
      },[])
  return (
    <div className='featured'>
        <h1 className='featured-text' data-aos="fade-up">Produits haut de gamme</h1>
        <div className='container' >
            
        </div>

    </div>
  );
}

export default Featured;