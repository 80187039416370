

import React,{useState, useRef, useEffect} from 'react';
import Form from 'react-bootstrap/Form';
import {motion} from 'framer-motion';

import CommonSection from '../components/Ul/CommonSection';

import Helmet from '../components/Helmet/Helmet'
import { Container, Row, Col } from 'reactstrap';

import '../styles/shop.css';

import products from '../assets/data/products';
//import productsList from '../components/Ul/ProductsList';
import ProductsList from '../components/Ul/ProductsList';

// carousels 
//import Carousel from 'react-bootstrap/Carousel';
//import ExampleCarouselImage from "../assets/images/Mosquito-20Away-20banner-02-desk_03_2024.jpg";
import ExampleCarouselImage2 from "../assets/images/LG_Tele_DD_03_2024.jpg";
//import ExampleCarouselImage3 from "../assets/images/Cadeux pour l'amour_03_2024.jpg";
//image
import Image from 'react-bootstrap/Image';

const Shop = () => {

  const [productsData, setProductsData] = useState(products)

  const handleFilter = (e)=>{
    const filterValue = e.target.value;
    if (filterValue === "LG audio") {
      const filteredProducts = products.filter(
        (item) => item.category === "LG audio"
      );

      setProductsData(filteredProducts);
    }

    if (filterValue === "LG tv") {
      const filteredProducts = products.filter(
        (item) => item.category === "LG tv"
      );

      setProductsData(filteredProducts);
    }


    if (filterValue === "LG refrigerateur") {
      const filteredProducts = products.filter(
        (item) => item.category === "LG refrigerateur"
      );

      setProductsData(filteredProducts);
    }


    if (filterValue === "LG split") {
      const filteredProducts = products.filter(
        (item) => item.category === "LG split"
      );

      setProductsData(filteredProducts);
    }

    if (filterValue === "LG machine à laver") {
      const filteredProducts = products.filter(
        (item) => item.category === "LG machine à laver"
      );

      setProductsData(filteredProducts);
    }

    if (filterValue === "LG micro-onde") {
      const filteredProducts = products.filter(
        (item) => item.category === "LG micro-onde"
      );

      setProductsData(filteredProducts);
    }

    if (filterValue === "LG lave vaisselle") {
      const filteredProducts = products.filter(
        (item) => item.category === "LG lave vaisselle"
      );

      setProductsData(filteredProducts);
    }

    if (filterValue === "LG cuisiniere") {
      const filteredProducts = products.filter(
        (item) => item.category === "LG cuisiniere"
      );

      setProductsData(filteredProducts);
    }

    if (filterValue === "LG congelateur") {
      const filteredProducts = products.filter(
        (item) => item.category === "LG congelateur"
      );

      setProductsData(filteredProducts);
    }

    //hisense

    if (filterValue === "Hisense audio") {
      const filteredProducts = products.filter(
        (item) => item.category === "Hisense audio"
      );

      setProductsData(filteredProducts);
    }

    if (filterValue === "Hisense tv") {
      const filteredProducts = products.filter(
        (item) => item.category === "Hisense tv"
      );

      setProductsData(filteredProducts);
    }


    if (filterValue === "Hisense refrigerateur") {
      const filteredProducts = products.filter(
        (item) => item.category === "Hisense refrigerateur"
      );

      setProductsData(filteredProducts);
    }


    if (filterValue === "Hisense split") {
      const filteredProducts = products.filter(
        (item) => item.category === "Hisense split"
      );

      setProductsData(filteredProducts);
    }

    if (filterValue === "Hisense machine à laver") {
      const filteredProducts = products.filter(
        (item) => item.category === "Hisense machine à laver"
      );

      setProductsData(filteredProducts);
    }

    if (filterValue === "Hisense micro-onde") {
      const filteredProducts = products.filter(
        (item) => item.category === "Hisense micro-onde"
      );

      setProductsData(filteredProducts);
    }

    if (filterValue === "Hisense lave vaisselle") {
      const filteredProducts = products.filter(
        (item) => item.category === "Hisense lave vaisselle"
      );

      setProductsData(filteredProducts);
    }

    if (filterValue === "Hisense cuisiniere") {
      const filteredProducts = products.filter(
        (item) => item.category === "Hisense cuisiniere"
      );

      setProductsData(filteredProducts);
    }

    if (filterValue === "Hisense congelateur") {
      const filteredProducts = products.filter(
        (item) => item.category === "Hisense congelateur"
      );

      setProductsData(filteredProducts);
    }


    //paco & Hisense

    if (filterValue === "paco") {
      const filteredProducts = products.filter(
        (item) => item.category === "paco"
      );

      setProductsData(filteredProducts);
    }

    if (filterValue === "perkins") {
      const filteredProducts = products.filter(
        (item) => item.category === "perkins"
      );

      setProductsData(filteredProducts);
    }

  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value.trim().toLowerCase();

    if (!searchTerm) {
      setProductsData(products); // Affiche tous les produits si le terme de recherche est vide
      return;
    }

    const searchedProducts = products.filter((item) => 
      item.productName && item.productName.toLowerCase().includes(searchTerm)
    );

    setProductsData(searchedProducts);
  };

  
  const searchInputRef = useRef(null);  

  useEffect(() => {  
    // Met le focus sur l'input quand le composant est monté  
    if (searchInputRef.current) {  
      searchInputRef.current.focus();  
    }  
  }, []); 

  return (  
  <Helmet title='shop'>
      <section>
        <Container>
          <Row className='container'>
            <Col lg='3' md='6' className='mb-5'>
              
               <Form.Select aria-label="Default select example" onChange={handleFilter}>
               <option value="LG audio">Filter by LG</option>
                  <option value="LG audio">Audio</option>
                  <option value="LG tv">tv</option>
                  <option value="LG refrigerateur">Refrigerateur</option>
                  <option value="LG split">Split</option>
                  <option value="LG machine à laver">Machine à laver</option>
                  <option value="LG micro-onde">Micro-Onde</option>
                  <option value="LG lave vaisselle">Lave Vaisselle</option>
                  <option value="LG cuisiniere">Cuisiniere</option>
                  <option value="LG congelateur">Congelateur</option>
               </Form.Select>
              
            </Col>
            <Col lg='3' md='6' className='text-end mb-5'>
            <Form.Select aria-label="Default select example" onChange={handleFilter}>
            <option value="LG audio">Filter by Hisense</option>
                <option value="Hisense audio">Audio</option>
                  <option value="Hisense tv">tv</option>
                  <option value="Hisense refrigerateur">Refrigerateur</option>
                  <option value="Hisense split">Split</option>
                  <option value="Hisense machine à laver">Machine à laver</option>
                  <option value="Hisense micro-onde">Micro-Onde</option>
                  <option value="Hisense lave vaisselle">Lave Vaisselle</option>
                  <option value="Hisense cuisiniere">Cuisiniere</option>
                  <option value="Hisense congelateur">Congelateur</option>
               </Form.Select>
            </Col>
           <Col lg='6' md='12'>
            <Form inline>
              <div className="search__box" id='search-form'>
                <Form.Control
                    type="text"
                    ref={searchInputRef} 
                    placeholder="Search"
                    className=" mr-sm-2" onChange={handleSearch}
                />
                  <motion.span whileTap={{ scale: 1.6 }}>
                    <i className='ri-search-line'></i>
                  </motion.span>
              </div>
            </Form>
            </Col> 
          </Row>
        </Container>
      </section>
      <section className='pt-0'>
        <Container>
          <Row>
            {
              productsData.length === 0 ? (
                <h1 className='text-center fs-4'>No products are found!</h1>
              ): (
                <ProductsList data={productsData} />
              )}
          </Row>
        </Container>
      </section>
      <section className='pb-1'>
      <Image src={ExampleCarouselImage2} fluid />;
      </section>
  </Helmet>
  );
  
};

export default Shop;