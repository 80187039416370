import React from 'react';


function NotFound() {
  return (
    <div>
        <h1 className='text-center mt-5 mb-5'>Page Not Found 404</h1>
    </div>
  )
}

export default NotFound