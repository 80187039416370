import React from 'react'
import saco1 from "../../assets/images/saco1.png";//audio
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import '../../styles/slider.css';
function Product_Saco1() {
  return (
    <div className='card display-flex' style={{padding: '8px 20px',marginLeft:'20px', alignItems:'center'}}>
    <Link to='/shop/255'><motion.img whileHover={{ scale: 0.9 }}  className='product--image' src={saco1} alt="" /></Link>
    <Link to='/shop/255'><h6 className='title'>Saco</h6></Link>
    
    <Link to='/shop/255'><motion.button className='button-slider' whileTap={{scale: 1.2}}  >Plus detail</motion.button></Link>
  </div>
  )
}

export default Product_Saco1