import React from 'react'
import Corredo4 from "../../assets/images/1- 60x60 Ceramic Grey_11.jpg";//audio
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import '../../styles/slider.css';


function Product_Cor4() {
  return (
    <div className='card display-flex' style={{padding: '8px 20px',marginLeft:'20px', alignItems:'center'}}>
    <Link to='/shop/216'><motion.img whileHover={{ scale: 0.9 }}  className='product--image' src={Corredo4} alt="" /></Link>
    <h6 className='title'>Corredo detail</h6>
    
    <Link to='/shop/216'><motion.button className='button-slider' whileTap={{scale: 1.2}}  >Plus detail</motion.button></Link>
  </div>
  )
}

export default Product_Cor4