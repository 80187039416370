import React from 'react';
import productEmtop from "../../assets/images/EMTOP_LOGO.jpg";
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import '../../styles/slider.css';


function Product_marqueEmtop() {
  return (
    <div className='card display-flex' style={{padding: '8px 20px',marginLeft:'20px'}}>
    <motion.img whileHover={{ scale: 0.9 }}  className='product--image' src={productEmtop} alt="" />
   
  </div>
   
  )
}

export default Product_marqueEmtop