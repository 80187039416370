import React from 'react'
import Paco3 from "../../assets/images/paco_stab_3.jpg";//audio
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import '../../styles/slider.css';


function Product_Pac3() {
  return (
    <div className='card display-flex' style={{padding: '8px 20px',marginLeft:'20px', alignItems:'center'}}>
    <Link to='/shop/228'><motion.img whileHover={{ scale: 0.9 }}  className='product--image' src={Paco3} alt="" /></Link>
    <Link to='/shop/228'><h6 className='title'>Paco detail</h6></Link>
    
    <Link to='/shop/228'><motion.button className='button-slider' whileTap={{scale: 1.2}}  >Plus detail</motion.button></Link>
  </div>
  )
}

export default Product_Pac3