import React from 'react'
import productHisense from "../../assets/images/Hisense.png";//audio
import { motion } from 'framer-motion';

import '../../styles/slider.css';


function Product_marqueHisense() {
  return (
    <div className='card display-flex' style={{padding: '8px 20px',marginLeft:'20px'}}>
    <motion.img whileHover={{ scale: 0.9 }}  className='product--image' src={productHisense} alt="" />
    
  </div>
  )
}

export default Product_marqueHisense