

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {getFirestore} from 'firebase/firestore';
import {getStorage} from 'firebase/storage';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBltiJjcbPb1one6s0sgvrddBTc7RnFYxE",
  authDomain: "maltimart-d9403.firebaseapp.com",
  projectId: "maltimart-d9403",
  storageBucket: "maltimart-d9403.appspot.com",
  messagingSenderId: "645303765614",
  appId: "1:645303765614:web:79f13edf0196d6a1b8347f"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

export default app;