import "./App.css";

import { useState, useEffect } from 'react';
import GridLoader from "react-spinners/GridLoader";
import Layout from "./components/Layout/Layout";


function App() {

  const [loading , setLoading] = useState(false)
  useEffect(()=>{
    setLoading(true)
    setTimeout(()=>{
        setLoading(false)

    },5000)
  },[])

  return (
    <div>
        {
            loading ? <div className="spinners">
            <GridLoader color="#d63636"/><br/>
            </div>
            :
            <Layout />
        }
    </div>
  );
}
export default App;
