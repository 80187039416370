import React from 'react'
import productEnzo from "../../assets/images/Enzahome.png";//audio
import { motion } from 'framer-motion';

import '../../styles/slider.css';


function Product_marqueEnzahome() {
  return (
    <div className='card display-flex' style={{padding: '8px 20px',marginLeft:'20px'}}>
    <motion.img whileHover={{ scale: 0.9 }}  className='product--image' src={productEnzo} alt="" />
   
  </div>
  )
}

export default Product_marqueEnzahome