
import React from 'react'

import '../../styles/clock.css';

import { PiTelevisionSimple } from "react-icons/pi";
import { LuRadioReceiver } from "react-icons/lu";
import { LuMicrowave } from "react-icons/lu";
import { LuRefrigerator } from "react-icons/lu";
import { TbAirConditioning } from "react-icons/tb";
import { TbCooker } from "react-icons/tb";

const Clock = () => {

    


  return (
    <div className="clock__wrapper d-flex align-items-center gap-3">
     <div className="clock__data d-flex align-items-center gap-3">
       <div className='text-center'>
         
         
        </div> 
        <LuRadioReceiver style={{color:'white', fontSize:'40px'}} />
        <LuMicrowave style={{color:'white', fontSize:'40px'}} />
        <LuRefrigerator style={{color:'white', fontSize:'40px'}} />
        <PiTelevisionSimple style={{color:'white', fontSize:'40px'}} />
        
<TbAirConditioning style={{color:'white', fontSize:'40px'}}  />

<TbCooker style={{color:'white', fontSize:'40px'}} />
     </div>

     <div className="clock__data d-flex align-items-center gap-3">
       <div className='text-center'>
        
        
        </div> 
        <span className='text-white fs-3'><i class="ri-armchair-line"></i></span>
     </div>

    </div>
  );
};

export default Clock;