import React from 'react';  
import '../GridLg/Grid1.css';  




import productImg01 from "../../../assets/images/quantum_B.png";
import productImg02 from "../../../assets/images/SPEAKER_A.png";

import productImg03 from "../../../assets/images/INVERTER900BTU.png";
import productImg04 from "../../../assets/images/ref76ws.png";
import img11 from '../../../assets/images/africa-computerelectronics-supercategory-9.jpg';
import img21 from "../../../assets/images/winter-sports-concept-composition.59e504a787b9d4d098dc.jpg";
import img31 from '../../../assets/images/view-woman-gas-station.b211a1d2da70eacbfa8e.jpg';
import img41 from '../../../assets/images/full-shot-couple-dog-home.a65754f1aee13f0c2e83.jpg';
import img51 from '../../../assets/images/corredo_banner_3.jpg';
import { Link } from 'react-router-dom';






const products = [  
  { id: 1, name: "98U7H QUANTUM " , desc: 'TV', image: productImg01, lien:'/shop/204' },  
  { id: 2, name: 'PARTY ROCKET ONE PLUS ', desc: 'Audio', image: productImg02, lien:'/shop/1123' },  
  { id: 3, name: 'INVERTER AC 24 000 BTU', desc: 'Climatiseur', image: productImg03, lien:'/shop/173' },  
  { id: 4, name: 'REF 76WS', desc: 'Refrigerateur', image: productImg04, lien:'/shop/160' },
];  

function GridHisense() {  
  return (  
    <div className="grid-container_grid">  
      {products.map(product => (  
        <div className="grid-item_grid" key={product.id}>  
        <Link to={product.lien}>
          <img src={product.image} alt={product.name} className="product-image_grid" />  
          <h3 className='hover-text mb- t-2'>{product.name}</h3>
          <p className='hover-text justify-center mb-3 '>{product.desc}</p>  
          <button className="buy-button mb-3">VOIR DETAIL</button>  </Link>
        </div>  
      ))}  
    </div>  
  );  
}  

export default GridHisense;